<template>
  <div class="paypal-button-wrapper">
    <div id="paypal-button-container"></div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import Settings from "@/settings.js";
export default {
  name: "PaypalButton",
  data() {
    return {
      isLoading: true
    };
  },
  props: {
    planId: String,
    customerEmail: String
  },
  methods: {
    subTest() {
      this.$store.dispatch("savePaypalSubscription", {
        subscriptionId: "ABC123"
      });
    }
  },
  mounted() {
    this.$loadScript(
      "https://www.paypal.com/sdk/js?client-id=" +
        Settings.paypal.clientId +
        "&vault=true"
    )
      .then(() => {
        const myPlan = this.planId;
        const myCustomerEmail = this.customerEmail;
        const store = this.$store;
        this.isLoading = false;
        // eslint-disable-next-line no-undef
        paypal
          .Buttons({
            createSubscription: function(data, actions) {
              return actions.subscription.create({
                plan_id: myPlan,
                subscriber: {
                  email: myCustomerEmail
                }
              });
            },

            onApprove: function(data) {
              console.log(data);
              const subscriptionID = data.subscriptionID;
              store.dispatch("savePaypalSubscription", {
                subscriptionId: subscriptionID
              });
            }
          })
          .render("#paypal-button-container");
      })
      .catch(() => {
        // Failed to fetch script
      });
  }
};
</script>
