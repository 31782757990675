<template>
  <section>
    <div class="container content">
      <b-loading
        :is-full-page="true"
        :active.sync="processingSubscription"
        :can-cancel="false"
      >
        <div class="loading-info">
          <img
            class="loading-logo"
            :src="require('@/assets/logo.png')"
            alt="EiS Logo"
          />

          <p>
            Vielen Dank! <br />Ihre Buchung wird verarbeitet, dies kann einige
            Minuten dauern...
          </p>
        </div>
      </b-loading>
      <h2 class="title">
        Wählen Sie das Abo, das Ihren Bedürfnissen am besten entspricht
      </h2>

      <div class="checkout checkout--step1" v-if="checkoutStep === 1">
        <p>
          Wir bieten aktuell zwei Abos an:
          <br />Das Einzelabo berechtigt zur Nutzung der EiS App mit einem
          Gerät. <br />Das Familienabo berechtigt zur Nutzung der EiS App auf
          bis zu 5 Geräten. <br />Beide Abos können mit einer monatlichen oder
          einer jährlichen Laufzeit gewählt werden und verlängern sich
          automatisch nach Ablauf um dieselbe Zeit.
        </p>
        <div class="level">
          <div class="level-item has-text-centered">
            <b-field>
              <b-radio-button
                v-model="displayInterval"
                native-value="month"
                type="is-success"
              >
                <span>Monatlich</span>
              </b-radio-button>

              <b-radio-button
                v-model="displayInterval"
                native-value="year"
                type="is-success"
              >
                <span>Jährlich</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
        <div class="pricing-table">
          <div
            class="pricing-plan is-info"
            v-for="plan in getPlans"
            v-bind:key="plan.id"
          >
            <div class="plan-header">{{ plan.name }}</div>
            <div class="plan-price">
              <span class="plan-price-amount">
                <span class="plan-price-currency">€</span>
                {{ formatPrice(plan.price) }}
              </span>
              / {{ plan.intervalName }}*
              <span v-if="plan.interval === 'year'">
                <br />
                (entspricht {{ calcMonthlyPrice(plan.price) }} € im Monat)
              </span>
            </div>
            <div class="plan-items">
              <div
                class="plan-item"
                v-for="feature in plan.features"
                v-bind:key="feature"
              >
                {{ feature }}
              </div>
            </div>
            <div class="plan-footer">
              <button
                class="button is-fullwidth is-success"
                @click="choosePlan(plan)"
              >
                Auswählen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container checkout checkout--step2" v-if="checkoutStep === 2">
        <div class="content">
          <h3 class="title">Zusammenfassung</h3>
          <p>
            Bitte überprüfen Sie Ihre Auswahl bevor Sie weiter zur Zahlung
            gehen.
          </p>
        </div>
        <div class="columns">
          <div class="column is-6-desktop is-12-mobile">
            <div class="card">
              <div class="card-content">
                <h4 class="title">Gewähltes Abo:</h4>
                <h5 class="title">{{ checkoutPlan.name }}</h5>
                <ul id="planProperties">
                  <li>
                    <span class="has-text-weight-medium">Abrechnung:</span>
                    {{ checkoutPlan.intervalAdjective }}
                  </li>
                  <li>
                    <span class="has-text-weight-medium">Preis:</span>
                    {{ formatPrice(checkoutPlan.price) }} €*
                  </li>
                  <li>
                    <span class="has-text-weight-medium">Anzahl Geräte:</span>
                    {{ checkoutPlan.devicesText }}
                  </li>
                </ul>
                <!-- <p>{{ checkoutPlan }}</p> -->
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div id="checkoutWrapper" class="column is-full">
              <Checkout
                :clientReferenceId="authUser.user.uid"
                :customerEmail="authUser.user.email"
                :stripePlanId="checkoutPlan.stripeId"
                :paypalPlanId="checkoutPlan.paypalId"
              ></Checkout>
            </div>
            <div class="column is-full">
              <button
                class="button is-fullwidth is-light"
                @click="checkoutStepBack()"
              >
                zurück zur Auswahl
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content is-small">
      <p>* alle Preisangaben inkl. 19% gesetzlicher MwSt.</p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Checkout from "@/components/Checkout.vue";
import Settings from "@/settings.js";

export default {
  name: "Header",
  components: {
    Checkout,
  },
  data: function () {
    return {
      //   plans: settings.plans,
      displayInterval: "year",
    };
  },
  computed: {
    ...mapGetters([
      "authUser",
      "checkoutStep",
      "checkoutPlan",
      "processingSubscription",
    ]),
    plans() {
      return Settings.subscriptionPlans;
    },
    getPlans() {
      return this.plans.filter(
        (plan) => plan.interval === this.displayInterval
      );
    },
  },
  methods: {
    checkoutStepBack() {
      this.$store.commit("setCheckoutStep", this.checkoutStep - 1);
    },

    choosePlan(plan) {
      this.$store.commit("setCheckoutPlan", plan);
      this.$store.dispatch("gotoNextCheckoutStep");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price);
    },
    calcMonthlyPrice(price) {
      const monthly = (price / 12).toFixed(2);
      return this.formatPrice(monthly);
    },
  },
};
</script>
