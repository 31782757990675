<template>
  <section>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <h2 class="subtitle">Bisherige Zahlungen</h2>
          </div>
        </div>
        <div class="card-content">
          <b-table v-if="payments" :data="payments">
            <template slot-scope="props">
              <b-table-column field="date" label="Datum">
                {{ getDateFromPaypal(props.row.create_time) }}
              </b-table-column>
              <b-table-column field="price" label="Betrag">
                {{ formatPrice(props.row.amount.total) }}
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";

export default {
  props: { payments: Array, type: String },

  methods: {
    getProductName(product) {
      return product ? product.name : "test";
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price) + " €";
    },

    translateInterval(interval) {
      return interval === "month" ? "monatlich" : "jährlich";
    },

    getDateFromPaypal(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    getDate(date) {
      if (typeof date === "undefined") {
        return "--";
      }
      if (isNaN(date)) {
        let seconds = date.seconds;
        return this.dateFromTimestamp(seconds);
      }
      return this.dateFromTimestamp(date);
    },

    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },

    confirmCancelSubscription() {
      let subscriptionId = this.subscription.id;
      this.$buefy.dialog.confirm({
        message: "Möchten Sie Ihr Abonnement wirklich kündigen?",
        onConfirm: () => {
          //   this.$buefy.toast.open("User confirmed " + subscriptionId);
          this.$store.dispatch("cancelSubscription", {
            subscriptionId: subscriptionId,
          });
        },
      });
    },

    reloadSubscription() {
      this.$store.dispatch("getPayments");
    },
  },
};
</script>
