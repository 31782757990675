<template>
  <section class="section">
    <div v-if="paymentStatus !== null">
      <div v-if="paymentStatus === 'error'">
        <b-notification type="is-danger" aria-close-label="Close notification"
          >Buchung nicht erfolgreich</b-notification
        >
      </div>
      <div v-if="paymentStatus === 'success'">
        <b-notification type="is-success" aria-close-label="Close notification"
          >Buchung erfolgreich</b-notification
        >
      </div>
    </div>
    <div v-if="typeof userData === 'undefined' || userData === null">
      <b-loading
        :is-full-page="true"
        :active="true"
        :can-cancel="false"
      ></b-loading>
    </div>
    <div v-else>
      <div v-if="!isPayedAccount">
        <PlanSelection />
      </div>
      <div v-if="isPayedAccount" class="container">
        <div class="columns is-multiline">
          <div class="column is-full">
            <CurrentPaypalSubscription
              v-if="
                userData.current_subscription &&
                userData.current_subscription.payment_provider === 'paypal'
              "
              :subscription="userData.current_subscription"
            />
            <CurrentPlan v-else :subscription="userData.current_subscription" />
          </div>
          <div class="column is-half">
            <Payments
              v-if="payments && payments.length > 0"
              :payments="payments"
              :type="userData.current_subscription.payment_provider"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="modal">
      <div class="modal-background"></div>
      <div class="modal-content"></div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </section>
</template>

<script>
import PlanSelection from "@/components/PlanSelection.vue";
import CurrentPaypalSubscription from "@/components/CurrentPaypalSubscription.vue";
import CurrentPlan from "@/components/CurrentPlan.vue";
import Payments from "@/components/Payments.vue";
// import { firestore } from "@/db";
import { mapGetters } from "vuex";
import moment from "moment";
import router from "@/router.js";

export default {
  name: "account",
  userData: null,
  data() {
    return {
      checkout: null,

      cancelSubscriptionUrl:
        "https://europe-west1-eis-app.cloudfunctions.net/cancelSubscription?subscriptionId=",
    };
  },
  components: {
    CurrentPaypalSubscription,
    PlanSelection,
    CurrentPlan,
    Payments,
  },

  created: function () {
    this.$store.dispatch("setCurrentView", "Account");
    this.$store.dispatch("bindUser", this.authUser);
    this.$store.dispatch("getPayments");

    if (typeof this.$route.params.paymentstatus !== "undefined") {
      setTimeout(() => {
        router.push({ name: "account" });
      }, 5000);
    }
  },

  methods: {
    getProductName(subscription) {
      return subscription.product ? subscription.product.name : "test";
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },

    translateInterval(interval) {
      return interval === "month" ? "monatlich" : "jährlich";
    },

    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },

    confirmCancelSubscription() {
      let subscriptionId = this.userData.current_subscription.id;
      this.$buefy.dialog.confirm({
        message: "Möchten Sie Ihr Abonnement wirklich kündigen?",
        onConfirm: () => {
          this.$toast.open("User confirmed");
          this.$store.dispatch("cancelSubscription", {
            subscriptionId: subscriptionId,
          });
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "userData",
      "authUser",
      "authToken",
      "payments",
    ]),
    paymentStatus() {
      return this.$route.params.paymentstatus;
    },
    isPayedAccount() {
      if (
        this.userData.current_subscription &&
        this.userData.current_subscription.cancel_at_period_end === false
      ) {
        return true;
      }
      if (
        this.userData.current_subscription &&
        this.userData.current_subscription.cancel_at_period_end === true
      ) {
        const now = moment(new Date());
        try {
          //   console.log(
          //     this.userData.current_subscription.current_period_end.toDate()
          //   );
          const period_end = this.userData.current_subscription.current_period_end.toDate();
          const diffInDays = now.diff(period_end, "days");

          return diffInDays > 1;
        } catch (e) {
          return false;
        }
      }
      return false;
    },
  },
};
</script>
