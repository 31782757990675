<template>
  <section>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <h2 class="subtitle">Aktueller Plan</h2>
          </div>
        </div>
        <div class="card-content">
          <b-table :data="[subscription]">
            <template slot-scope="props">
              <b-table-column field="id" label="ID">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="plan" label="Name">
                {{ props.row.nickname }}
              </b-table-column>

              <b-table-column field="interval" label="Abrechnungsintervall">
                {{ translateInterval(props.row.interval) }}
              </b-table-column>

              <b-table-column field="price" label="Preis" centered>
                {{ formatPrice(props.row.price) }}
              </b-table-column>

              <b-table-column
                field="period_end"
                label="Nächste Verlängerung"
                centered
              >
                {{ getDate(props.row.current_period_end) }}
              </b-table-column>

              <b-table-column label="">
                <div v-if="props.row.cancel_at_period_end === true">
                  Abonnement wird zum
                  {{ getDate(props.row.current_period_end) }}
                  beendet und nicht automatisch verlängert.
                </div>
                <b-button
                  v-else
                  class="button is-danger"
                  @click="confirmCancelSubscription"
                  :loading="props.row.cancelling"
                >
                  Abonnement beenden
                </b-button>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- <b-button @click="reloadSubscription">reload</b-button> -->
    </div>
  </section>
</template>

<script>
import moment from "moment";

export default {
  props: { subscription: Object },

  methods: {
    getProductName(product) {
      return product ? product.name : "test";
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },

    translateInterval(interval) {
      return interval === "month" ? "monatlich" : "jährlich";
    },

    getDate(date) {
      if (typeof date === "undefined") {
        return "--";
      }
      if (isNaN(date)) {
        let seconds = date.seconds;
        return this.dateFromTimestamp(seconds);
      }
      return this.dateFromTimestamp(date);
    },

    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },

    confirmCancelSubscription() {
      let subscriptionId = this.subscription.id;
      this.$buefy.dialog.confirm({
        message: "Möchten Sie Ihr Abonnement wirklich kündigen?",
        onConfirm: () => {
          //   this.$buefy.toast.open("User confirmed " + subscriptionId);
          this.$store.dispatch("cancelPaypalSubscription", {
            subscriptionId: subscriptionId,
          });
        },
      });
    },

    reloadSubscription() {
      this.$store.dispatch("getPayments");
    },
  },
};
</script>
